import axios from 'axios'

const request = axios.create({
  baseURL: 'https://www.wuhan-huicheng.cn/jc',
  timeout: 5000
})

// request 请求拦截器
request.interceptors.request.use(config => {
  let tenantName = localStorage.getItem("tenantName");
  let token = localStorage.getItem("token");
  if (tenantName || token) {
    config.headers['tenantName'] = tenantName;
    config.headers['token'] = token;
  } else {
    return false;
  }
  return config;
}, error => Promise.reject(error))

// request 响应拦截器
request.interceptors.response.use(res => {
    return res.data;
}, error => Promise.reject(error.response.data))

export default request