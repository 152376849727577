import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      requireAute: true,
    },
    redirect: '/home',
  },{
    path: '/home',
    name: 'home',
    meta: {
      requireAute: true,
    },
    component: () => import('../views/Home.vue')
  },{
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },{
    path: '/file',
    name: 'file',
    component: () => import('../views/File.vue'),
  },{
    path: '/warnHome',
    name: 'warnHome',
    component: () => import('../views/WarnHome.vue'),
  },{
    path: '/Place',
    name: 'Place',
    component: () => import('../views/Place.vue'),
  },{
    path: '/tree',
    name: 'tree',
    component: () => import('../views/Tree.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.requireAute){
		if(localStorage.getItem('token')){
			next()
		}else{
			next({path:'/login'})
		}
	}else{
		next()
	}
})

export default router