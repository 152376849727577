import Vue from 'vue'
import flexlble from './utils/flexible.js'
import tools from './utils/tools'
import request from './utils/request.js'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import './plugins/element'
import echarts from './plugins/eCharts.js' 
import 'vis-network/standalone/esm/vis-network'
Vue.use(ElementUI);
Vue.use(flexlble)

Vue.config.productionTip = false
Vue.prototype.$tools = tools
Vue.prototype.$request = request
Vue.prototype.$echarts = echarts

new Vue({
  router,
  beforeCreate(){
    Vue.prototype.$bus = this;
  },
  render: h => h(App),
}).$mount('#app')
