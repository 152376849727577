// 保存自定义工具函数
const tools = {}

tools.dateFormat = time => {
  let date=new Date(time);
  let year=date.getFullYear();
  let month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
  let day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
  let hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
  let minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
  let seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
  return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
}

tools.computeDate = datas => {
  let date = new Date();
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  let time = new Date(Y + M + D).getTime();
  let newDatas = datas.filter(val => {
    let newTime = new Date(val.createTime);
    return newTime > time
  })
  return newDatas;
}

tools.datas = (measure,num) => {
  if (!measure) {
    return '';
  }
  let s = '';
  let measureList = JSON.parse(localStorage.getItem("measureList"));
  for (const m of measureList) {
    if (measure[m.id] == null || m.id === 'para1s' || m.id === 'para9') {
      continue;
    }
    if (m.unit === null) {
      m.unit = '';
    }
    if(num && m.id === 'para1'){
      if (measure[m.id] >= 200){
        s += `${m.name}:故障,`;
      } else {
        s += `${m.name}:${measure[m.id]}${m.unit},`;
      }
    } else if (m.id === 'para6') {
      if (measure[m.id] === "1") {
        s += `正常,`;
      } else {
        s += `故障,`;
      }
    } else if (m.id === 'para2s' || m.id === 'para3s'){
      s += `${measure[m.id]},`
    } else {
      s += `${m.name}:${measure[m.id]}${m.unit},`
    }
  }
  if (!s) return '当前时间没有数据';
  return s.substring(0, s.length - 1);
}

tools.getMeasureNames = measureTypes => {
  const names = [];
  let x = 0;
  const measures = ['para3','para5','para8','para9','para12','para1s','para2s','para3s'];
  let measureList = JSON.parse(localStorage.getItem("measureList"));
  for (let i = 0; i < measureTypes.length; i++) {
    if(measures.indexOf(measureTypes[i]) >= 0) continue;
    measureList.forEach(m => {
      if (m.id === measureTypes[i]) {
        names[x] = m.name;
        x++;
      }
    });
  }
  return names;
}

tools.getMeasureTimes = (datas,num = 12) => {
  const times = [];
  let length = datas.length < num ? datas.length : num;
  for (let i = 0; i < length; i++) {
    times[i] = datas[i].createTime.slice(5,10);
  }
  return times;
}

tools.getmeasureDatas = (measureTypes = [],historyDatas = [],num = 12) => {
  const datas = [];
  let x = 0;
  const measures = ['para3','para5','para8','para9','para12','para1s','para2s','para3s'];
  for (let i = 0; i < measureTypes.length; i++) {
    if (measures.indexOf(measureTypes[i]) >= 0 ) continue;
    const measure = [];
    let length = historyDatas.length < num ? historyDatas.length : num;
    for (let j = 0; j < length; j++) {
      if (historyDatas[j][measureTypes[i]] !== null) {
        measure[j] = historyDatas[j][measureTypes[i]];
      }
    }
    datas[x] = measure;
    x++;
  }
  return datas;
}

tools.getMeasureStr = (list = []) => {
  if(list === null){
      return ''
  }
  let s = '';
  for (let i = 0; i < list.length; i++) {
      s += `${list[i]},`;
  }
  if (!s) return s;
  return s.substring(0, s.length - 1);
}

tools.getHistoryDatas = (historyDatas = []) => {
  if (historyDatas.length === 0) {
    return [];
  }
  const datas = [historyDatas[0]];
  let time = new Date(historyDatas[0].createTime.slice(0,10)).getTime();
  let j = 1;
  for (let i = 1; i < historyDatas.length; i++) {
    if (j >= 30) {
      break;
    }
    let newTime = new Date(historyDatas[i].createTime.slice(0,10)).getTime();
    if (time !== newTime) {
      datas[j] = historyDatas[i];
      time = newTime;
      j++;
    }
  }
  return datas;
}

tools.computeBoo = val => {
  // 定义设备类别
	const facility = ["1","2","3","5","6","7","8","9","E","F"]
  if(val.length === 4){
    if(facility.indexOf(val[0]) >= 0){
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export default tools;