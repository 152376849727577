import Vue from 'vue'
import { 
  Button, 
  Table, 
  TableColumn, 
  Pagination, 
  Autocomplete,
  Input, 
  Link,
  Form,
  FormItem,
  Select,
  Option,
  Message,
  Dialog,
  Popconfirm,
  Drawer,
  Image,
  Cascader,
  MessageBox
} from 'element-ui';


Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Autocomplete)
Vue.use(Input)
Vue.use(Link)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Popconfirm)
Vue.use(Drawer)
Vue.use(Image)
Vue.use(Cascader)

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;